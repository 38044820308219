import { useEffect, useMemo } from "react";

import { debounce } from "helpers/debounce";
import { isDev } from "helpers/isDev";
import { isFunction } from "helpers/isFunction";
import { useLatest } from "hooks";

type noop = (...args: any[]) => any; 

const useDebounceFn = <T extends noop>(fn: T, options?: any) => {
    if (isDev) {
        if (!isFunction(fn)) {
            console.error(`useDebounceFn expected parameter is a function, got ${typeof fn}`);
        }
    }

    const fnRef = useLatest(fn);

    const wait = options?.wait ?? 1000;

    const debounced = useMemo(
        () => {
            return debounce(
                (...args: Parameters<T>): ReturnType<T> => {
                    return fnRef.current(...args);
                },
                wait
            )
        },
        [fnRef, wait]
    );

    useEffect(() => {
        return debounced.cancel;
    }, [debounced.cancel]);

    return {
        run: debounced,
        cancel: debounced.cancel,
        flush: debounced.flush
    } as const;
}

export default useDebounceFn;
