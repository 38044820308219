import styles from "./input.module.scss";
import cs from "classnames";
import { ForwardedRef, forwardRef, useState } from "react";

import { Icon } from "common/components/ui/icon";
import { CheckOfMedium, CheckOnMedium } from "common/icons";

export const CheckboxInput = forwardRef(({
  name,
  label,
  className,
  disabled,
  placeholder,
  onChange,
  isRequired,
  isError,
  iconLeft,
  iconRight,
  isSmall,
  value: valueProp,
  errors,
  type,
  onKeyDown,
  ...rest
}: any, ref: ForwardedRef<HTMLInputElement>) => {
  const [value, setValue] = useState(valueProp || false);
  
  return (
    <div
      className={cs(
        styles.inputWrapper,
        {
          [styles.disabled]: disabled,
          [styles.error]: isError,
          [styles.small]: isSmall,
        },
        className
      )}
    >
      <label
        htmlFor={`checkbox-input=${name}`}
        className={cs(
          styles.inputLabel,
          { [styles.required]: isRequired },
        )}
      >
        {label}

        <div className={styles.inputField}>
          {
            value && (<Icon component={CheckOnMedium} />)
          }

          {
            !value && (<Icon component={CheckOfMedium} />)
          }
          <input
            id={`checkbox-input=${name}`}
            ref={ref}
            name={name}
            className={styles.input}
            placeholder={placeholder}
            disabled={disabled}
            onChange={(e) => {
              setValue(e.target.checked);
              onChange && onChange(e);
            }}
            value={value}
            type={"checkbox"}
            {...rest}
          />
        </div>
      </label>
      <div className={styles.errorMessage}>{errors?.[name] ? <>{errors[name]?.message}</> : <></>}</div>
    </div>
  );
});
