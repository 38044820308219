type Debounced = {
    (...args: any): any;
    cancel: () => void;
    flush: () => void;
}
type Debounce = {
    (callback: (...args: any) => any, wait: number | undefined): Debounced,
};

const debounce: Debounce = (callback, wait) => {
    let timer: NodeJS.Timeout;

    const cancel = () => {
        if (timer) {
            clearTimeout(timer)
        }
    };

    const debounced: Debounced = (...args: any[]) => {
        cancel();

        timer = setTimeout(() => callback(...args), wait);
    };

    debounced.cancel = cancel;
    debounced.flush = callback;

    return debounced;
};

export { debounce };
